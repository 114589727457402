<!--
 * @Author: DY
 * @Date: 2021-02-25 16:39:22
 * @LastEditTime: 2022-02-23 17:41:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\salesBusiness\InfoMaintenance\index.vue
-->
<template>
    <div class="InfoMaintenance" v-loading="loading">
        <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="业务员：">
                <el-input v-model="form.salesman_name" readonly>
                    <el-button slot="append" @click="selectSalesman">
                        <i class="iconfont iconyewuyuan"></i>
                    </el-button>
                </el-input>
            </el-form-item>
            <el-form-item label="业务员电话：">
                <el-input v-model="form.salesman_phone" readonly></el-input>
            </el-form-item>
        </el-form>
        <footer>
            <el-button @click="close">
                取消
            </el-button>
            <el-button type="primary" @click="confirm">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
export default {
    // 基本信息维护[销售管理-合同管理-零售业务台账-基本信息维护]
    name: 'info-maintenance',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            form: {
                contract_number: '',
                salesman_id: '',
                salesman_name: '',
                salesman_phone: '',
            },
        };
    },
    computed: {},
    watch: {},
    created() {
        this.form.contract_number = this.extr.contract_number;
        // 拉取合同详情
        this.getRetailDetail();
    },
    mounted() {},
    destroyed() {},
    methods: {
        // 关闭
        close() {
            this.$parent.hide();
        },
        // 拉取合同详情
        async getRetailDetail() {
            this.loading = true;
            const res = await this.getRetailDetailApi();
            if (res) {
                this.form.salesman_id = res.salesman_id;
                this.form.salesman_name = res.salesman_name;
                this.form.salesman_phone = res.salesman_phone;
            }
            this.loading = false;
        },
        // 选择业务员
        selectSalesman() {
            this.$toast({
                title: true,
                text: '选择人员',
                type: 'eject',
                width: '10rem',
                height: '80%',
                t_url: 'selectUser/selectUser',
                viewPosition: 'components',
                extr: {
                    isCurrentDept: true,
                    sureCallBack: this.salesmanCallBack,
                },
            });
        },
        // 选择业务员-回调
        salesmanCallBack(data) {
            this.form.salesman_id = data.User_Id;
            this.form.salesman_name = data.User_Name;
            this.form.salesman_phone = data.Mobile;
        },
        // 确认
        confirm() {
            this.$axios
                .put('/interfaceApi/sale/contract_manager/maintain_retail', this.form)
                .then(() => {
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                    this.$parent.hide();
                    this.extr.table.getTableList();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 获取零售业务台账明细
        getRetailDetailApi() {
            return this.$axios.$get('/interfaceApi/sale/contract_manager/get_retail_detailById/' + this.extr.contractId, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
.InfoMaintenance
    height 100%
    padding 0.2rem
    footer
        text-align center
        position absolute
        bottom 0.3rem
        left 0
        width 100%
        button
            width 1.84rem
            height 0.44rem
            cursor pointer
            margin 0 0.2rem
</style>